import {
  Center,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { AiOutlineClose, AiOutlineInfoCircle } from "react-icons/ai";

interface CardFilesProps {
  file_name: string;
}

interface ModalCardProps {
  card: {
    title: string;
    uuid: string;
    desc?: string;
    primary_artist: {
      name: string;
    };
    files?: CardFilesProps[];
  };
  price: boolean;
  showOpen: boolean;
}

export default function CardAboutModal({
  card,
  price,
  showOpen,
}: ModalCardProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const backgroundColor = colorMode === "light" ? "white" : "black";

  return (
    <>
      <Center>
        <AiOutlineInfoCircle onClick={onOpen} size={22} />
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
        <ModalOverlay
          backdropInvert="80%"
          backdropFilter="blur(10px) hue-rotate(10deg)"
        />

        <ModalContent
          backgroundColor={backgroundColor}
          maxW={340}
          borderRadius={20}
        >
          <AiOutlineClose
            onClick={onClose}
            size={24}
            style={{ right: 15, top: 15, position: "absolute" }}
          />
          <ModalHeader>About {card.title}</ModalHeader>
          {card.primary_artist && (
            <ModalBody mb={0}>
              <Text>Primary Artist: {card.primary_artist.name}</Text>
            </ModalBody>
          )}

          {card.desc && (
            <>
              <ModalBody>
                <Text mb={2}>{card.desc}</Text>
              </ModalBody>
            </>
          )}
          <ModalHeader>Included items</ModalHeader>
          <ModalBody mb={5}>
            <UnorderedList>
              {card.files &&
                card.files.map(
                  (
                    file: {
                      file_name: string;
                    },
                    index: number
                  ) => <ListItem key={index}>{file.file_name}</ListItem>
                )}
              <ListItem>WAV and MP3 Download</ListItem>
              <ListItem>Unlimited Streaming</ListItem>
              <ListItem>Proof Of Fan</ListItem>
              <ListItem>
                Listen on the go with the Songcards mobile app
              </ListItem>
            </UnorderedList>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
