import {
  Box,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { FaRegHeart } from "react-icons/fa";
import PlayBox from "./PlayBox";
import Thumbnail from "./Thumbnails";
import GiftCheckoutWrapper from "./stripe/GiftCheckoutWrapper";

interface CardFilesProps {
  file_name: string;
}

interface ModalCardProps {
  card: {
    id: number;
    title: string;
    uuid: string;
    desc?: string;
    primary_artist: {
      name: string;
    };
    files?: CardFilesProps[];
    ext_file?: string;
    comp_file?: string;
    song: {
      ext_file?: string;
    };
  };
  price: boolean;
  showOpen: boolean;
}

export default function ThisIsModal({ card, price, showOpen }: ModalCardProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const backgroundColor = colorMode === "light" ? "white" : "black";

  return (
    <>
      <Center>
        {price && <Text fontSize="2xl">$ 2.99</Text>}
        <FaRegHeart size={20} onClick={onOpen} />
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
        <ModalOverlay
          backdropInvert="80%"
          backdropFilter="blur(10px) hue-rotate(10deg)"
        />
        <ModalContent
          backgroundColor={backgroundColor}
          maxW={320}
          borderRadius={20}
        >
          <AiOutlineClose
            onClick={onClose}
            size={24}
            style={{ right: 15, top: 15, position: "absolute" }}
          />
          <ModalBody mb={0} mt={0} alignItems="center">
            <Heading fontSize={20} mb={3} mt={3}>
              {card.title}
            </Heading>
            {card.primary_artist && <Text>by {card.primary_artist.name}</Text>}
            <Center>
              <Box
                height={285}
                width={285}
                borderColor={colorMode === "dark" ? "white" : "black"}
                mb={2}
                mt={5}
              >
                <Thumbnail
                  ext_file={card.ext_file}
                  comp_file={card.comp_file}
                />
              </Box>
            </Center>
            <PlayBox card={card} />
            <Text fontSize="2xl" mt={4}>
              $ 2.99
            </Text>
            <div style={{ marginTop: 20 }}>
              <GiftCheckoutWrapper
                id={card.id}
                art={""}
                uuid={card.uuid}
                issued={""}
                price={2.99}
                image={""}
                ext_file={card.song.ext_file}
              />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
