import { urlSetter } from "@/counterSlice";
import { useAppSelector } from "@/hooks";
import { Box, Heading, useColorMode } from "@chakra-ui/react";
import { AiOutlineEllipsis } from "react-icons/ai";
import { BiPlay } from "react-icons/bi";
import { useDispatch } from "react-redux";

interface PlayBoxProps {
  card: {
    title: string;
    uuid: string;
  };
}

export default function PlayBox({ card }: PlayBoxProps) {
  const dispatch = useDispatch();
  const cardPlaying = useAppSelector((state) => state.nowplaying.card);
  const { colorMode } = useColorMode();

  return (
    <>
      <Box
        border="1px solid #ccc"
        borderRadius={10}
        p={3}
        display="flex"
        alignItems="center"
        style={{ cursor: "pointer" }}
        onClick={() => dispatch(urlSetter(card))}
        borderColor={colorMode == "dark" ? "white" : "black"}
        borderStyle="dotted"
        w={270}
      >
        <Heading size="md" flex="1" as="h1">
          {card.title}
        </Heading>
        {cardPlaying.uuid != card.uuid && (
          <div style={{ background: "transparent", border: "none" }}>
            <BiPlay size={30} />
          </div>
        )}
        {cardPlaying.uuid == card.uuid && <AiOutlineEllipsis size={30} />}
      </Box>
    </>
  );
}
